/* eslint-disable no-underscore-dangle */
import { Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { WindowUtils } from '../utils/window.util';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { Omnisend } from '../models/omnisend.model';
import { Product } from '@app/akita/api/products/models/product.model';

const OMNISEND_ELEMENT_ID = 'popsy-omnisend';
const OMNISEND_BRAND_ID = '6218be4acc2400f27258344b';
const OMNISEND_SDK_URL = `https://omnisnippet1.com/inshop/launcher-v2.js`;
const BASE_10 = 10;

@Injectable({
  providedIn: 'root',
})
export class OmnisendService {
  private readonly document: Document | null;
  private installingOmnisendSubject: Subject<Omnisend | null> | null;

  private lastViewedProduct?: string | null;
  private lastViewName?: string | null;

  constructor(
    private readonly injector: Injector,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.installingOmnisendSubject = null;

    if (this.akitaRouterQuery.isBrowser) {
      try {
        this.document = this.injector.get<Document | null>(DOCUMENT, null);
      } catch (err) {
        this.document = null;
      }
    } else {
      this.document = null;
    }
  }

  public increasePageViewCount(): void {
    try {
      const windowRef: any | null = WindowUtils.window;
      if (windowRef?._omnisend?.tracking?.incViews) {
        windowRef._omnisend.tracking.incViews();
      }
    } catch (err) {
      /* SSR Not Supported */
    }
  }

  public trackPageView(viewName?: string | null): void {
    if (viewName && viewName !== this.lastViewName) {
      this.lastViewName = viewName;
      try {
        const windowRef: any | null = WindowUtils.window;
        if (windowRef?.omnisend?.push) {
          windowRef.omnisend.push(['track', '$pageViewed']);
        }
      } catch (err) {
        /* SSR Not Supported */
      }
    }
  }

  public identifyContact(id?: string | null): void {
    if (id) {
      try {
        const windowRef: any | null = WindowUtils.window;
        if (windowRef?._omnisend?.cookies?.set) {
          windowRef._omnisend.cookies.set('omnisendContactID', `${id || ''}`);
        }
        if (windowRef?._omnisend) {
          windowRef._omnisend.contactIdentified = true;
        }
        if (windowRef?._omnisend?.user?.handle && windowRef?._omnisend?.cookies?.get) {
          windowRef._omnisend.user.handle({
            contactID: windowRef._omnisend.cookies.get('omnisendContactID'),
            sessionID: windowRef._omnisend.cookies.get('omnisendSessionID'),
            anonymousID: windowRef._omnisend.cookies.get('omnisendAnonymousID'),
          });
        }
      } catch (err) {
        /* SSR Not Supported */
      }
    }
  }

  public isContactIdentified(): boolean {
    let isIdentified = false;
    try {
      const windowRef: any | null = WindowUtils.window;
      isIdentified = Boolean(windowRef?._omnisend?.contactIdentified);
    } catch (err) {
      /* SSR Not Supported */
    }
    return isIdentified;
  }

  public getContactId(): string | null {
    let contactId: string | null = null;
    try {
      const windowRef: any | null = WindowUtils.window;
      if (windowRef?._omnisend?.cookies?.get) {
        contactId = windowRef._omnisend.cookies.get('omnisendContactID');
      }
    } catch (err) {
      /* SSR Not Supported */
    }
    return contactId;
  }

  private get omnisendInstance(): Omnisend | null {
    const windowRef: any | null = WindowUtils.window;
    if (windowRef) {
      if (!windowRef.omnisend) {
        windowRef.omnisend = [];
        windowRef.omnisend.push(['accountID', OMNISEND_BRAND_ID]);
        this.trackPageView('initial');
      }
      return windowRef.omnisend as Omnisend;
    }
    return null;
  }

  public trackProductView(product?: Product | null): void {
    if (
      this.omnisendInstance &&
      this.document &&
      product?.id &&
      product?.title &&
      product?.price?.currency &&
      product?.price?.amount &&
      this.lastViewedProduct !== product?.id
    ) {
      this.lastViewedProduct = product.id;

      const windowRef: any | null = WindowUtils.window;
      if (windowRef && windowRef.omnisend) {
        // Price in cents (integer)
        const price = parseInt(
          `${product.price.amount || 0}`.replace(/[.,]/g, ''),
          BASE_10
        );

        // Old price in cents (integer)
        let origPrice = price;
        let image = '';
        let canonicalUrl = '';
        let variantId: string | null = null;
        if (product instanceof Product) {
          origPrice = parseInt(
            `${product.originalPrice.amount || 0}`.replace(/[.,]/g, ''),
            BASE_10
          );
          image = product.images[0] || '';
          canonicalUrl =
            `https://${product.country}.popsy.app/p/${product.id}?lang=${product.language}`.toLowerCase();
          variantId = product.id;
        }

        if (canonicalUrl && variantId && image && windowRef?.omnisend) {
          windowRef.omnisend.push([
            'track',
            '$productViewed',
            {
              $productID: `${product.id || ''}`,
              $variantID: `${variantId || ''}`,
              $currency: `${product.price.currency || 'USD'}`,
              $price: price,
              $oldPrice: origPrice,
              $title: `${product.title || ''}`,
              $description: `${product.description || ''}`,
              $imageUrl: `${image || ''}`,
              $productUrl: `${canonicalUrl || ''}`,
            },
          ]);
        }
      }
    }
  }

  public uninstallOmnisend(): void {
    if (this.document) {
      if (this.omnisendInstance) {
        const windowRef: any | null = WindowUtils.window;
        if (windowRef && windowRef.omnisend) {
          windowRef.omnisend = null;
        }
      }

      const elementRef = this.document.getElementById(OMNISEND_ELEMENT_ID);
      if (elementRef) {
        elementRef.remove();
      }
    }
  }

  public installOmnisend(): Observable<Omnisend | null> {
    const subscription = new Subscription();
    return new Observable((observer: Observer<Omnisend | null>) => {
      if (this.document && environment.enableOmnisend) {
        if (this.installingOmnisendSubject && !this.installingOmnisendSubject.closed) {
          subscription.add(
            this.installingOmnisendSubject.asObservable().subscribe({
              next: (instance: Omnisend | null) => {
                observer.next(instance);
                observer.complete();

                if (subscription) {
                  subscription.unsubscribe();
                }
              },
              error: (error: unknown) => {
                observer.next(error);
                observer.complete();

                if (subscription) {
                  subscription.unsubscribe();
                }
              },
            })
          );
        } else {
          if (this.omnisendInstance) {
            observer.next(this.omnisendInstance);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          }

          const elementRef = this.document.getElementById(OMNISEND_ELEMENT_ID);
          if (elementRef) {
            elementRef.remove();
          }

          const headElement = this.document.getElementsByTagName('head');
          let parentElement = null;
          if (headElement.length > 0) {
            parentElement = headElement[0];
          } else {
            parentElement = this.document.getElementsByTagName('body')[0];
          }

          const script = this.document.createElement('script');
          script.setAttribute('id', OMNISEND_ELEMENT_ID);
          script.setAttribute('nonce', '760cace5bd9c8c98d2e2893f57c101b9');
          script.setAttribute('type', 'text/javascript');
          script.setAttribute('charset', 'utf-8');
          script.setAttribute('async', 'true');
          script.setAttribute('src', OMNISEND_SDK_URL);

          script.addEventListener('load', () => {
            const instance = this.omnisendInstance;
            if (
              this.installingOmnisendSubject &&
              !this.installingOmnisendSubject.closed
            ) {
              this.installingOmnisendSubject.next(instance);
              this.installingOmnisendSubject.complete();
              this.installingOmnisendSubject = null;
            }

            observer.next(instance);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          });

          script.addEventListener('error', (error: ErrorEvent) => {
            if (
              this.installingOmnisendSubject &&
              !this.installingOmnisendSubject.closed
            ) {
              this.installingOmnisendSubject.error(error);
              this.installingOmnisendSubject = null;
            }
            observer.error(error);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          });

          // Initialize
          const windowRef: any | null = WindowUtils.window;
          if (windowRef) {
            if (!windowRef.omnisend) {
              windowRef.omnisend = [];
              windowRef.omnisend.push(['accountID', OMNISEND_BRAND_ID]);
              this.trackPageView('initial');
            }

            // Start Loading
            this.installingOmnisendSubject = new Subject();
            parentElement?.appendChild(script);
          } else {
            observer.next(null);
            observer.complete();

            if (subscription) {
              subscription.unsubscribe();
            }
          }
        }
      } else {
        observer.next(null);
        observer.complete();

        if (subscription) {
          subscription.unsubscribe();
        }
      }
    });
  }
}
